<template>
    <button
        class="core-icon-button"
        :class="classes"
        v-tooltip="{ value: $t(tooltip) }"
        @mouseover="$emit('mouseover')"
        @mouseleave="$emit('mouseleave')"
        @click="$emit('click')"
    >
        <slot />
    </button>
</template>

<script>
export default {
    name: 'CoreIconButton',
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },

        color: {
            type: String,
            default: 'primary',
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        tooltip: {
            type: String,
            required: true,
        },
    },

    computed: {
        classes() {
            return {
                'core-icon-button--active': this.isActive,
                'core-icon-button--color-primary': this.color === 'primary',
                'core-icon-button--color-secondary': this.color === 'secondary',
                'core-icon-button--color-warning': this.color === 'warning',
                'core-icon-button--disabled': this.disabled,
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.core-icon-button {
    width: 40px;
    height: 40px;
    font-size: 1.4rem;
    font-weight: bold;
    cursor: pointer;
    background-color: transparent;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    transition: 0.1s all;
}

.core-icon-button--color-primary {
    color: $cp;
    border: 1px solid $cp;

    &:hover {
        background-color: $cp;
        border: 1px solid $cp;
        color: $cn-10;
    }
}

.core-icon-button--active.core-icon-button--color-primary {
    background-color: $cp;
    color: $cn-10;
    border: 1px solid $cp;

    &:hover {
        background-color: lighten($cp, 5%);
        border: 1px solid lighten($cp, 5%);
    }
}

.core-icon-button--color-secondary {
    color: $cs;
    border: 1px solid $cs;

    &:hover {
        background-color: $cs;
        border: 1px solid $cs;
        color: $cn-10;
    }
}

.core-icon-button--active.core-icon-button--color-secondary {
    background-color: $cs;
    color: $cn-10;
    border: 1px solid $cs;

    &:hover {
        background-color: lighten($cs, 5%);
        border: 1px solid lighten($cs, 5%);
    }
}

.core-icon-button--color-warning {
    color: $color-warning;
    border: 1px solid $color-warning;

    &:hover {
        background-color: $color-warning;
        border: 1px solid $color-warning;
        color: $cn-10;
    }
}

.core-icon-button--active.core-icon-button--color-warning {
    background-color: $color-warning;
    color: $cn-10;
    border: 1px solid $color-warning;

    &:hover {
        background-color: lighten($color-warning, 10%);
        border: 1px solid lighten($color-warning, 10%);
    }
}

.core-icon-button--disabled {
    color: $cn;
    border: 1px solid $cn;
    background-color: transparent;

    &:hover {
        cursor: not-allowed;
        background-color: transparent;
        border: 1px solid $cn;
        color: $cn;
    }
}
</style>
