<template>
    <form class="create-role-modal" @submit.prevent="handleCreate">
        <CorePageLoader :loading="saving" />

        <div class="create-role-input-container">
            <CoreStringInput
                placeholder="§permissions.set_new_role_name"
                name="role_name"
                :fullWidth="true"
                v-model="roleName"
                :disabled="saving"
                required
            />
        </div>

        <div class="actions">
            <CoreButtonPrimary
                label="§ui_button.create_new"
                type="submit"
                :loading="saving"
                :disabled="saving"
            />
        </div>
    </form>
</template>

<script>
import CorePageLoader from '@/components/core/CorePageLoader'
import CoreBorderWrapper from '@/components/core/CoreBorderWrapper'
import CoreStringInput from '@/components/core/CoreStringInput'
import CoreButtonPrimary from '@/components/core/CoreButtonPrimary'
import CoreButtonSecondary from '@/components/core/CoreButtonSecondary'
import CoreTwoColumnsRow from '@/components/core/CoreTwoColumnsRow'

export default {
    name: 'CreateRoleModal',

    components: {
        CorePageLoader,
        CoreBorderWrapper,
        CoreStringInput,
        CoreButtonPrimary,
        CoreButtonSecondary,
        CoreTwoColumnsRow
    },

    data() {
        return {
            saving: false,
            roleName: ''
        }
    },

    methods: {
        async handleCreate() {
            this.saving = true

            try {
                await this.$store.dispatch('settings/createNewRole', {
                    vm: this,
                    roleName: this.roleName
                })

                await this.$store.dispatch('settings/getRolesList', { vm: this })
                this.$store.commit('modal/SET_CLOSE')

            } catch (error) {
                refreshError(dispatch, vm)
            } finally {
                this.saving = false
            }
        },

        handleClose() {
            this.$store.commit('modal/SET_CLOSE')
        }
    }
}
</script>

<style scoped>
.create-role-modal {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px;
}
.create-role-input-container {
    margin-bottom: 20px;
}
.actions {
    display: flex;
    justify-content: flex-end;
}
</style>
