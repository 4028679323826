<template>
    <div class="create-token-modal">
        <CorePageLoader :loading="loading" />

        <div v-if="token">
            <div class="flex flex-gap-2 align-center">
                <CoreStringInput
                    name="token"
                    class="flex-grow-1 flex-shrink-1 flex-basis-60"
                    :condensed="true"
                    :value="token"
                    :disabled="true"
                />
            </div>

            <p class="mt-2 flex align-center flex-gap-2">
                <CoreIconButton
                    color="primary"
                    tooltip="§ui.tooltip_copy_to_clipboard"
                    @mouseover="isCopyToClipboardHover = true"
                    @mouseleave="isCopyToClipboardHover = false"
                    @click="copyToClipboard"
                >
                    <mdicon
                        :class="isCopyToClipboardHover ? 'icon--white' : 'icon--color-primary'"
                        name="content-copy"
                        size="22"
                    />
                </CoreIconButton>
                <span class="font-color-75 text-200">
                    {{ $t('§iot_connectivity.save_token_message') }}
                </span>
            </p>

            <div class="flex justify-end flex-gap-2 mt-5">
                <CoreButtonPrimary
                    label="§ui_button.create_another_token"
                    icon="pi-undo"
                    @click="resetInputs"
                />
                <CoreButtonSecondary
                    label="§ui_button.close"
                    @click="handleClose"
                />
            </div>
        </div>

        <div v-else>
            <CoreTwoColumnsRow class="mb-3">
                <template #left>
                    <CoreBorderWrapper
                        :condensed="true"
                        class="flex-grow-1 flex-shrink-1 flex-basis-40"
                    >
                        {{ $t('§iot_connectivity.select_user') }}
                    </CoreBorderWrapper>
                </template>
                <template #right>
                    <CoreDropdown
                        name="user"
                        class="flex-grow-1 flex-shrink-1 flex-basis-60"
                        :condensed="true"
                        :options="$store.state.invite.ownedUsersOptions"
                        :value="selectedUser"
                        @input="selectedUser = $event"
                    />
                </template>
            </CoreTwoColumnsRow>

            <CoreTwoColumnsRow class="mb-3">
                <template #left>
                    <CoreBorderWrapper
                        :condensed="true"
                        class="flex-grow-1 flex-shrink-1 flex-basis-40"
                    >
                        {{ $t('§iot_connectivity.token_name') }}
                    </CoreBorderWrapper>
                </template>
                <template #right>
                    <CoreStringInput
                        name="token_name"
                        class="flex-grow-1 flex-shrink-1 flex-basis-60"
                        :condensed="true"
                        :value="tokenName"
                        @input="tokenName = $event"
                    />
                </template>
            </CoreTwoColumnsRow>

            <div class="flex justify-end flex-gap-2">
                <CoreButtonPrimary
                    label="§ui_button.create"
                    icon="pi-check"
                    @click="handleCreateToken"
                    :loading="loading"
                    :disabled="isDisabled"
                />
                <CoreButtonSecondary
                    label="§ui_button.close"
                    @click="handleClose"
                />
            </div>
        </div>
    </div>
</template>

<script>
import CorePageLoader from '@/components/core/CorePageLoader'
import CoreBorderWrapper from '@/components/core/CoreBorderWrapper'
import CoreTwoColumnsRow from '@/components/core/CoreTwoColumnsRow'
import CoreStringInput from '@/components/core/CoreStringInput'
import CoreDropdown from '@/components/core/CoreDropdown'
import CoreButtonPrimary from '@/components/core/CoreButtonPrimary'
import CoreButtonSecondary from '@/components/core/CoreButtonSecondary'
import CoreIconButton from '@/components/core/CoreIconButton'
import { apiPost, handleError } from '@/helpers/api'

export default {
    name: 'CreateTokenModal',

    components: {
        CorePageLoader,
        CoreBorderWrapper,
        CoreTwoColumnsRow,
        CoreStringInput,
        CoreDropdown,
        CoreButtonPrimary,
        CoreButtonSecondary,
        CoreIconButton,
    },

    data() {
        return {
            loading: false,
            selectedUser: null,
            tokenName: '',
            token: '',
            isCopyToClipboardHover: false,
        }
    },

    computed: {
        isDisabled() {
            return this.selectedUser === null || this.tokenName === ''
        },
    },

    methods: {
        async handleCreateToken() {
            try {
                this.loading = true
                const response = await apiPost(this.route('token.store'), {
                    user: this.selectedUser,
                    name: this.tokenName,
                })
                this.loading = false
                await this.$store.dispatch('modal/handleConfirmation')
                this.token = response.accessToken
            } catch (error) {
                const notification = {
                    type: 'error',
                    text: error.message || error.response.data.message,
                }
                await new Promise((resolve) => setTimeout(resolve, 100))
                this.$store.dispatch('notification/add', notification)
                this.loading = false
            }
        },

        handleClose() {
            this.$emit('close-modal')
        },

        async copyToClipboard() {
            const triggerNotification = (notification) => {
                this.$store.dispatch('notification/add', notification)
            }

            const isAllowed = await this.checkClipboardPermission()

            if (isAllowed) {
                navigator.clipboard.writeText(this.token).then(
                    function () {
                        triggerNotification({
                            type: 'success',
                            text: 'notifications.success_clipboard_copy',
                        })
                    },
                    function () {
                        triggerNotification({
                            type: 'error',
                            text: 'notifications.error_clipboard_copy',
                        })
                    }
                )
            }
        },

        checkClipboardPermission() {
            // firefox has clipboard-write permission true by default
            if (navigator.userAgent.indexOf('Firefox') > -1) {
                return true
            }

            // safari does not support permission API
            if (navigator.userAgent.indexOf('Safari') > -1) {
                return true
            }

            const result = navigator.permissions.query({
                name: 'clipboard-write',
            })

            return result
        },

        resetInputs() {
            this.selectedUser = null
            this.tokenName = ''
            this.token = ''
        },
    },
}
</script>

<style scoped>
.create-token-modal {
    padding: 40px;
}
</style>
