import * as Sentry from '@sentry/vue'
import { refreshError } from '@/helpers/api'
import { store } from '../store'

/* eslint-disable import/prefer-default-export */
const MODULES_LISTS = {
    client: {
        module: 'client',
        listName: 'clientOptions',
    },
    country: { module: 'country', listName: 'countryOptions' },
    countryFinancingAvailable: {
        module: 'country',
        listName: 'countryFinancingAvailableOptions',
    },
    endUserIndustry: { module: 'industry', listName: 'endUserIndustryOptions' },

    // to loop also over that list, it is not saved in module but on the vm
    oemIndustry: { module: 'industry', listName: 'oemIndustryOptions' },
    billingUnit: { module: 'billingUnit', listName: 'billingUnitOptions' },
    ownedUsers: { module: 'invite', listName: 'ownedUsersOptions' },
    roles: { module: 'invite', listName: 'rolesOptions' },
    unitType: { module: 'unitType', listName: 'unitTypeOptions' },
}

const getOptionsList = (list) => {
    try {
        return Object.keys(list).map((label) => ({
            label,
            value: list[label],
        }))
    } catch (error) {
        Sentry.setContext('Getting options list (/helpers/viewVars/getOptionsList)', {
            error,
            argsList: list,
        })

        refreshError(store.dispatch)
    }
}

export const setInState = ({ lists, commit, vm }) => {
    try {
        Object.keys(MODULES_LISTS).forEach((name) => {
            const list = lists[name]
            const { listName } = MODULES_LISTS[name]
            const { module } = MODULES_LISTS[name]
            const optionsList = getOptionsList(list)
            commit('SET_VIEWS_VARS', { module, listName, list: optionsList }, { root: true })
        })
    } catch (error) {
        Sentry.setContext(
            'Initial adding items to view vars state (/helpers/viewVars/setInState)',
            {
                error,
                argsLists: lists,
            }
        )

        refreshError(store.dispatch)
    }
}

export const areViewsVarsInState = ({ state }) => {
    const result = Object.keys(MODULES_LISTS).map((name) => {
        const moduleState = state[MODULES_LISTS[name].module]
        if (moduleState === undefined) return false

        const listState = moduleState[MODULES_LISTS[name].listName]
        if (listState === undefined) return false

        return true
    })

    return result.every((el) => el)
}

const listWithNewItem = ({ state, listName, item }) => {
    try {
        const moduleName = MODULES_LISTS[listName].module
        const optionsListName = MODULES_LISTS[listName].listName

        const moduleState = state[moduleName]
        const listState = moduleState[optionsListName]

        const list = [...listState, item]

        return { moduleName, optionsListName, list }
    } catch (error) {
        Sentry.setContext('Add new item to views vars (/helpers/viewVars/listWithNewItem', {
            error,
            argsListName: listName,
            argsItem: item,
            module: MODULES_LISTS[listName].module,
            moduleState: state[MODULES_LISTS[listName].module],
            listName: MODULES_LISTS[listName].listName,
            listState: state[MODULES_LISTS[listName].module][MODULES_LISTS[listName].listName],
        })

        refreshError(store.dispatch)
    }
}

export const addNewItem = ({ state, commit, listName, item }) => {
    const { list, moduleName, optionsListName } = listWithNewItem({
        state,
        listName,
        item,
    })

    commit(
        'SET_VIEWS_VARS',
        { module: moduleName, listName: optionsListName, list },
        { root: true }
    )
}

const listWithoutItem = ({ state, listName, item }) => {
    try {
        const moduleName = MODULES_LISTS[listName].module
        const optionsListName = MODULES_LISTS[listName].listName

        const moduleState = state[moduleName]

        const listState = moduleState[optionsListName]
        if (listState === undefined || listState.length === 0) return false

        const list = listState.filter((listItem) => listItem.value !== item.value)

        return { moduleName, optionsListName, list }
    } catch (error) {
        Sentry.setContext('Remove item from views vars (/helpers/viewVars/listWithoutItem', {
            error,
            argsListName: listName,
            argsItem: item,
            module: MODULES_LISTS[listName].module,
            moduleState: state[MODULES_LISTS[listName].module],
            listName: MODULES_LISTS[listName].listName,
            listState: state[MODULES_LISTS[listName].module][MODULES_LISTS[listName].listName],
        })

        refreshError(store.dispatch)
    }
}

export const removeItem = ({ state, commit, listName, item }) => {
    const { list, moduleName, optionsListName } = listWithoutItem({
        state,
        listName,
        item,
    })

    commit(
        'SET_VIEWS_VARS',
        { module: moduleName, listName: optionsListName, list },
        { root: true }
    )
}
