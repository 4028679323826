import { apiPost, apiGet, handleError, refreshError, fetchUserData } from '@/helpers/api'

const namespaced = true

export const OEMS = {
    washtec: 'washtec',
}

const state = () => ({
    user: {
        permissions: [],
        digitalId: {},
        home: '',
        company_name: '',
        contact_email: '',
        id: null,
        oem: '',
        email: '',
        name: '',
        preferred_locale: '',
        preferred_date_format: '',
        preferred_number_format: '',
        invoice_mail_send_copy_to: '',
        currency_code: '',
        street: '',
        postal_code: '',
        city: '',
        country_id: null,
        vat_identification_number: '',
        company_registration_number: '',

        isSidebarCollapsed: false,
        isSidebarManuallyCollapsed: null,
    },
    localesOptions: [],
    countriesOptions: [],
})

const mutations = {
    SET_INPUT(state, { name, value }) {
        state.user[name] = value
    },

    UPDATE_USER(state, { newUser }) {
        state.user = newUser
    },

    SET_PERMISSIONS_ARRAY(state, { permissions }) {
        state.user.permissions = [...permissions]
    },

    SET_USER_PROP(state, { name, value }) {
        state.user[name] = value
    },

    SET_LOCALES_OPTIONS(state, { value }) {
        state.localesOptions = [...value]
    },

    SET_COUNTRIES_OPTIONS(state, { value }) {
        state.countriesOptions = value
    },

    SET_LOGIN(state, { status, message }) {
        // if no status and message, reset status
        state.login = {
            status,
            message,
        }
    },

    SET_USER_ID(state, { id }) {
        state.user.id = id
    },

    SET_LOCALE(state, { locale }) {
        state.user.preferred_locale = locale
    },
}

export const actions = {
    updateUser({ state, commit }, { fieldName, newValue }) {
        const newUser = state.user
        newUser[fieldName] = newValue
        commit('UPDATE_USER', { newUser })
    },

    setUserData({ state, commit }, { userData }) {
        const newUser = {
            ...state.user,
            ...userData,
        }

        commit('UPDATE_USER', { newUser })
    },

    handleLoginError({ dispatch }, { message }) {
        const notification = {
            type: 'error',
            text: message,
        }

        dispatch('notification/add', notification, { root: true })
    },

    async login({ dispatch }, { vm, email, password, remember }) {
        try {
            return apiPost(vm.route('vue_login'), {
                email,
                password,
                remember,
            })
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async logout({ dispatch }, { vm }) {
        try {
            await apiPost(vm.route('logout'))
            const path = '/'
            dispatch('goToPage', { path }, { root: true })
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async fetchUser({ dispatch, commit }, { vm, userId }) {
        try {
            dispatch('showLoader', null, { root: true })
            const response = await apiGet(vm.route('profile.show', { user: userId }))
            if (response === null) {
                return
            }

            const { user_address } = response

            const user = { ...user_address[0], ...response }
            delete user.user_address

            Object.keys(user)
                .filter((el) => el !== 'locales')
                .forEach((name) => {
                    if (name === 'preferred_locale') {
                        const value =
                            user.preferred_locale.length === 2
                                ? `${user.preferred_locale}_EU`
                                : user.preferred_locale

                        commit('SET_INPUT', {
                            name,
                            value,
                        })
                    } else {
                        commit('SET_INPUT', {
                            name,
                            value: user[name],
                        })
                    }
                })
            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async fetchDigitalId({ dispatch, commit }, { vm }) {
        try {
            dispatch('showLoader', null, { root: true })
            const response = await apiGet(vm.route('permission.permission_by_category'))
            if (response === null) {
                return
            }
            commit('SET_INPUT', {
                name: 'digitalId',
                value: response
            })
            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async saveUser({ dispatch, state }, { vm }) {
        try {
            dispatch('showLoader', null, { root: true })
            const locale =
                state.user.preferred_locale.value === undefined
                    ? state.user.preferred_locale
                    : state.user.preferred_locale.value

            const data = {
                ...state.user,
                locale,
            }
            delete data.preferred_locale
            delete data.permissions
            delete data.home
            await apiPost(vm.route('profile.update'), {
                ...data,
            })

            window.location.reload()
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async setLocale({ commit, dispatch }, { vm, locale }) {
        try {
            commit('SET_LOCALE', { locale })
            await apiPost(vm.route('profile.locale'), {
                preferred_locale: locale,
            })
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async updatePassword({ dispatch }, { vm, current_password, password, password_confirmation }) {
        try {
            dispatch('showLoader', null, { root: true })
            const response = await apiPost(vm.route('auth.change_password'), {
                current_password,
                password,
                password_confirmation,
            })

            dispatch('hideLoader', null, { root: true })

            if (response.status === 'success') {
                const notification = {
                    type: 'success',
                    text: vm.$t('notifications.success_updated', {
                        name: 'password',
                    }),
                }

                dispatch('notification/add', notification, { root: true })
                return true
            }
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async register({ dispatch }, { vm, invite, name, password, preferred_locale }) {
        try {
            await apiPost(vm.route('invited_submit', { invite }), {
                name,
                password,
                preferred_locale,
            })

            const path = '/'
            dispatch('goToPage', { path }, { root: true })
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    // forgot password request
    async requestPasswordReset({ dispatch }, { email, vm }) {
        try {
            await apiPost(vm.route('password.email'), {
                email,
            })

            const notification = {
                type: 'success',
                text: vm.$t('§public.notification_reset_password_email_sent'),
            }

            dispatch('notification/add', notification, { root: true })

            setTimeout(() => {
                const path = '/'
                dispatch('goToPage', { path }, { root: true })
            }, 5000)
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    // forgot password - set new password
    async setNewPassword({ dispatch }, { email, password, passwordConfirm, token, vm }) {
        try {
            await apiPost(vm.route('reset_password'), {
                email,
                password,
                password_confirmation: passwordConfirm,
                token,
            })

            const notification = {
                type: 'success',
                text: vm.$t('§public.notification_password_changed_successfully'),
            }

            dispatch('notification/add', notification, { root: true })

            setTimeout(() => {
                const path = '/app/home'
                dispatch('goToPage', { path }, { root: true })
            }, 5000)
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async getUserData({ dispatch }, { vm }) {
        try {
            dispatch('showLoader', null, { root: true })
            const route = vm.route('app.user_data')
            const { lists, userData } = await fetchUserData(route, dispatch, vm)
            userData.preferred_locale =
                userData.preferred_locale.length === 2
                    ? `${userData.preferred_locale}_EU`
                    : userData.preferred_locale

            const language = userData.preferred_locale.split('-')[0]

            vm.$i18n.locale = language

            dispatch('hideLoader', null, { root: true })
            dispatch('user/setUserData', { userData }, { root: true })
            dispatch('setViewsVars', { lists }, { root: true })
        } catch (error) {
            dispatch('hideLoader', null, { root: true })
            refreshError(dispatch, vm)
        }
    },
}

const getters = {
    can: (state) => (input) => {
        if (state.user.permissions.length === 0) return false
        if (Array.isArray(input)) {
            return state.user.permissions.some((permission) => {
                return input.includes(permission)
            })
        }
        return state.user.permissions.includes(input)
    },
    getCountries: (state) => state.countriesOptions,
    getLocale: (state) => state.user.preferred_locale,
    // check does user has permission to at least one item (link, view) in navigation group
    isNavigationGroupShown: (state) => (neededPermissionsList) => {
        return neededPermissionsList.some((permissionName) =>
            state.user.permissions.includes(permissionName)
        )
    },
    isSidebarEffectiveCollapsed(state) {
        if (state.user.isSidebarManuallyCollapsed !== null) {
            return state.user.isSidebarManuallyCollapsed
        }
        return state.user.isSidebarCollapsed
    },
}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions,
}
