<template>
    <form class="create-role-modal" @submit.prevent="handleEdit">
        <CorePageLoader :loading="saving" />

        <div class="create-role-input-container">
            <CoreStringInput
                placeholder="§permissions.set_new_role_name"
                name="role_name"
                :fullWidth="true"
                v-model="roleName"
                :disabled="saving"
                required
            />
        </div>
        <div class="actions">
            <CoreButtonSecondary
                type="button"
                :label="deleteButtonLabel"
                @click="handleDeleteClick"
                :disabled="saving"
                :danger="true"
            />
            <CoreButtonPrimary
                label="§ui_button.save"
                type="submit"
                :loading="saving"
                :disabled="saving"
            />
        </div>
    </form>
</template>

<script>
import CorePageLoader from '@/components/core/CorePageLoader'
import CoreBorderWrapper from '@/components/core/CoreBorderWrapper'
import CoreStringInput from '@/components/core/CoreStringInput'
import CoreButtonPrimary from '@/components/core/CoreButtonPrimary'
import CoreButtonSecondary from '@/components/core/CoreButtonSecondary'
import CoreTwoColumnsRow from '@/components/core/CoreTwoColumnsRow'

export default {
    name: 'EditRoleModal',

    components: {
        CorePageLoader,
        CoreBorderWrapper,
        CoreStringInput,
        CoreButtonPrimary,
        CoreButtonSecondary,
        CoreTwoColumnsRow
    },

    data() {
        return {
            saving: false,
            roleName: '',
            confirmDelete: false
        }
    },

    created() {
        this.roleName = this.$store.state.modal.data.name || ''
    },

    computed: {
        deleteButtonLabel() {
            return this.confirmDelete ? this.$t('§ui_button.confirm_deletion') : this.$t('§ui_button.delete')
        }
    },

    methods: {
        async handleEdit() {
            this.saving = true

            try {
                await this.$store.dispatch('settings/editRoleName', {
                    vm: this,
                    roleId: this.$store.state.modal.data.id,
                    roleName: this.roleName
                })

                await this.$store.dispatch('settings/getRolesList', { vm: this })
                this.$store.commit('modal/SET_CLOSE')

            } catch (error) {
                refreshError(dispatch, vm)
            } finally {
                this.saving = false
            }
        },

        handleDeleteClick() {
            if (this.confirmDelete) {
                this.deleteRole()
            } else {
                this.confirmDelete = true
            }
        },

        async deleteRole() {
            this.saving = true

            try {
                await this.$store.dispatch('settings/deleteRole', {
                    vm: this,
                    roleId: this.$store.state.modal.data.id
                })

                await this.$store.dispatch('settings/getRolesList', { vm: this })
                this.$store.commit('modal/SET_CLOSE')

            } catch (error) {
                refreshError(dispatch, vm)
            } finally {
                this.saving = false
            }
        },

        handleClose() {
            this.$store.commit('modal/SET_CLOSE')
        }
    }
}
</script>

<style scoped>
.create-role-modal {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px;
}
.create-role-input-container {
    margin-bottom: 20px;
}
.actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
